import React, { ReactElement, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import useTypedSelector from '@/hooks/useTypedSelector';
import useTypedDispatch from '@/hooks/useTypedDispatch';
import { toggleStep } from '@/store/WorkSheet';
import { getUser } from '@/store/User/actions';
import { setAuth } from '@/store/Auth';
import { toggleLoader } from '@/store/Loader';
import UserRoleType from '@/enums/userRole';
import { UserWorkSheetStatus } from '@/enums/userWorkSheet';
import THEME from '@/styles/theme';
import GlobalStyle from '@/styles/global';
import LayoutProps from '@/interfaces/Layout';
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import Content from '@/components/common/Content';
import Loader from '@/components/common/Loader';

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { registered } = useTypedSelector((state) => state.register);
  const { logined } = useTypedSelector((state) => state.login);
  const { auth } = useTypedSelector((state) => state.auth);
  const { loader } = useTypedSelector((state) => state.loader);
  const { worksheet, role } = useTypedSelector((state) => state.user);
  const dispatch = useTypedDispatch();
  const router = useRouter();

  // useEffect(() => {
  //   const timer = () => {
  //     console.log('timer', timer);
  //     setTimeout(() => {
  //       dispatch(toggleLoader(true));
  //     }, 2000);
  //   };
  //
  //   // return clearTimeout(timer as any);
  // }, []);

  useEffect(() => {
    if (registered || logined) {
      dispatch(setAuth(true));
    } else {
      dispatch(setAuth(false));
    }
  }, [registered, logined]);

  useEffect(() => {
    if (auth) {
      dispatch(getUser()).finally(() => {
        dispatch(toggleLoader(false));
      });
    } else {
      setTimeout(() => {
        dispatch(toggleLoader(false));
      }, 200);
    }
  }, [auth]);

  useEffect(() => {
    if (worksheet && role) {
      if (role === UserRoleType.Coach) {
        if (Cookies.get('redirect_to_worksheet') === 'redirect') {
          router.push('/coach-account');
          Cookies.set('redirect_to_worksheet', 'no_redirect');
          return;
        } else {
          return;
        }
      } else {
        switch (worksheet.status) {
          case UserWorkSheetStatus.Processing:
            if (Cookies.get('redirect_to_worksheet') === 'redirect') {
              dispatch(toggleStep(worksheet.step));
              router.push('/account/worksheet');
              break;
            } else {
              break;
            }
          case UserWorkSheetStatus.Filled:
            if (Cookies.get('redirect_to_worksheet') === 'redirect') {
              if (router.pathname === '/catalog') {
                router.push('catalog');
              } else if (router.pathname === '/contacting') {
                router.push('/contacting');
              } else {
                router.push('/account');
              }
              Cookies.set('redirect_to_worksheet', 'no_redirect');
              break;
            } else {
              break;
            }
          default:
            break;
        }
      }
    }
  }, [worksheet, role]);

  const renderLoader = (): ReactElement => <Loader />;

  return (
    <ThemeProvider theme={THEME['dark']}>
      <GlobalStyle />
      <Header />
      <Content>{children}</Content>
      <Footer />
      {loader && renderLoader()}
    </ThemeProvider>
  );
};

export default Layout;
