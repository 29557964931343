import styled from 'styled-components';

const BaseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    //max-width: 344px;
    width: 100%;
    margin: 0;
    padding: 16px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    background: transparent;
    border: none;
    border-radius: 14px;
    cursor: pointer;
    position: relative;

    transition: background-color 0.3s ease-in-out;

    :focus {
        background: linear-gradient(89.91deg, #4b81fe 0.18%, #8330ff 100.03%);
        border-radius: 14px;
    }

    :before {
        content: '';
        position: absolute;
        inset: 0;
        padding: 2px;
        background: linear-gradient(90deg, #4b80fe 0%, #8131ff 100%);
        border-radius: 14px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;

        //-webkit-mask-image: -webkit-linear-gradient(#fff 0%, #fff 100%),
        //-webkit-linear-gradient(#fff 0%, #fff 100%);
        //-webkit-mask-repeat: no-repeat;
        //-webkit-mask-position: center;
        //-webkit-mask-size: cover;
        //-webkit-mask-composite: xor;
    }

    :disabled {
        opacity: 0.6;
        pointer-events: none;
    }
`;

export default BaseButton;
